<template src="./login.htm">
    
</template>

<script>
import Form from '@/utils/form'
import { mapState } from 'vuex';

export default {
    data() {
        return {
            show1: false,
            form: new Form({
                email: '',
                password: ''
            })
        }
    },
    computed: {
    ...mapState({
        user: state => state.auth.user,
        isAuthenticated: state => state.auth.isAuthenticated,
    }),
  },
    methods: {
        signIn() {
        this.form.$clearErrors()
        this.form.$busy = true
            this.$api
                .post('auth/login', {
                    email: this.form.email,
                    password: this.form.password
                })
                .then(({ data }) => {
                    if(data) {
                        this.$store.commit('auth/setUser', data);
                        this.form.$busy = false
                        this.$router.push({ name: 'auth.otp' })
                    }
                })
                .catch(err => {
                    this.form.$busy = false

                    this.$toast.open({
                        message:  err.response.data.msg,
                        type: 'error',
                        position: 'bottom'
                    })

                if (err.response.status === 422) {
                    this.form.$setErrors(err.response.data.errors)
                }
            })
        },

    },
    watch: {
        'form.email'() {
            this.form.$clearError('username')
        },
        'form.password'() {
            this.form.$clearError('password')
        }
    },
    mounted() {
        // if (this.isAuthenticated) {
        //     this.$router.replace({ name: 'auth.login' })
        // }
    },
}
</script>